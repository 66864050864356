
import { forwardRef } from "react"
import { Link as ChakraLink, Button } from "@chakra-ui/react"
import NextLink from "next/link"

export const Link = forwardRef((props, ref) => {
  const {
    href,
    locale,
    prefetch,
    hrefAs,
    replace,
    scroll,
    shallow,
    ...restProps
  } = props
  return (
    <NextLink
      passHref
      href={href}
      locale={locale}
      prefetch={prefetch}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      as={hrefAs}
    >
      <ChakraLink ref={ref} {...restProps} />
    </NextLink>
  )
})

export const ButtonLink = forwardRef((props, ref) => {
  const {
    href,
    locale,
    prefetch,
    hrefAs,
    replace,
    scroll,
    shallow,
    ...restProps
  } = props
  return (
    <NextLink
      passHref
      href={href}
      locale={locale}
      prefetch={prefetch}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      as={hrefAs}
    >
      <Button as="a" ref={ref} {...restProps} />
    </NextLink>
  )
})
