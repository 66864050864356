const Icon = ({
  children,
  size = "24",
  viewBox = "0 0 24 24",
  strokeWidth = 2,
  stroke = "currentcolor",
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={viewBox}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="square"
      strokeLinejoin="bevel"
      aria-hidden="true"
    >
      {children}
    </svg>
  )
}
export const CsvIcon = ({
  size = "24",
  strokeWidth = "none",
  fill = "currentcolor",
}) => {
  return (
    <Icon size={size} stroke="none" strokeWidth={strokeWidth} fill={fill}>
      <path fill="#116A3D" vectorEffect="non-scaling-stroke" d="M22 13.5H2l-1.5-2 2-2h19l2 2-1.5 2z" />
      <path fill="#E9EAEC" vectorEffect="non-scaling-stroke" d="M20.5 23.5h-17a1 1 0 01-1-1v-21a1 1 0 011-1h12l6 6v16a1 1 0 01-1 1z" />
      <path fill="#C0C3C9" vectorEffect="non-scaling-stroke" d="M15.5.5v5a1 1 0 001 1h5l-6-6z"/>
      <path fill="#25A263" vectorEffect="non-scaling-stroke" d="M22.5 20.5h-21a1 1 0 01-1-1v-8h23v8a1 1 0 01-1 1z"/>
      <path fill="#ffffff" vectorEffect="non-scaling-stroke" d="M8.893 14.888a.676.676 0 00-.591.31c-.141.206-.212.491-.212.855 0 .756.288 1.134.862 1.134.174 0 .342-.025.505-.073.163-.049.327-.107.492-.175v.897a2.711 2.711 0 01-1.112.218c-.593 0-1.047-.172-1.363-.516-.316-.344-.474-.84-.474-1.49 0-.406.076-.763.229-1.072.154-.308.374-.545.66-.71a1.997 1.997 0 011.015-.248c.424 0 .83.092 1.216.276l-.325.835a3.738 3.738 0 00-.435-.172 1.52 1.52 0 00-.467-.07zM13.28 16.808c0 .243-.061.46-.185.648a1.213 1.213 0 01-.534.44 1.977 1.977 0 01-.82.158c-.26 0-.48-.019-.657-.055a2.417 2.417 0 01-.553-.192v-.946c.202.104.412.185.63.243.22.058.42.087.602.087.158 0 .273-.027.347-.081a.252.252 0 00.11-.211c0-.054-.015-.1-.044-.141a.523.523 0 00-.143-.122 5.896 5.896 0 00-.522-.253 2.482 2.482 0 01-.576-.338 1.037 1.037 0 01-.284-.376 1.243 1.243 0 01-.093-.502c0-.362.131-.644.395-.846.263-.202.625-.303 1.085-.303.406 0 .82.094 1.243.281l-.325.82c-.367-.169-.684-.253-.95-.253-.138 0-.238.024-.301.073a.219.219 0 00-.094.18c0 .076.04.145.12.206.079.061.295.172.648.333.338.153.573.316.705.49.13.175.197.395.197.66zM16.093 14.074h1.187L15.993 18h-1.249l-1.28-3.926h1.192l.534 1.99c.111.442.172.75.183.923a7.654 7.654 0 01.175-.913l.545-2z"
      />
    </Icon>
  )
}


export const HistogramIcon = ({
  size = "24",
  strokeWidth = "none",
  fill = "currentcolor",
}) => {
  return (
    <Icon size={size} stroke="none" strokeWidth={strokeWidth} fill={fill}>
      <path
        d="M1.75 1.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v21a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-21zM6.25 9a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v13.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V9zM10.75 4.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v18a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-18zM15.25 17.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-5zM19.75 11.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-11z"
        vectorEffect="non-scaling-stroke"
      />
    </Icon>
  )
}

export const BarplotIcon = ({
  size = "24",
  strokeWidth = "none",
  fill = "currentcolor",
}) => {
  return (
    <Icon size={size} stroke="none" strokeWidth={strokeWidth} fill={fill}>
      <path
        d="M22 1a1 1 0 011 1v2a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1h20zM18 7a1 1 0 011 1v2a1 1 0 01-1 1H2a1 1 0 01-1-1V8a1 1 0 011-1h16zM14 13a1 1 0 011 1v2a1 1 0 01-1 1H2a1 1 0 01-1-1v-2a1 1 0 011-1h12zM7 19a1 1 0 011 1v2a1 1 0 01-1 1H2a1 1 0 01-1-1v-2a1 1 0 011-1h5z"
        vectorEffect="non-scaling-stroke"
      />
    </Icon>
  )
}

export const LineIcon = ({ size = "24", strokeWidth = "none" }) => {
  return (
    <Icon size={size} stroke="none" strokeWidth={strokeWidth}>
      <path
        fill="#003C7A"
        vectorEffect="non-scaling-stroke"
        d="M15.717 19a.746.746 0 01-.361-.093L5.546 13.5H1.75a.75.75 0 010-1.5h3.99c.125 0 .25.032.36.093l9.55 5.265 5.848-4.454a.749.749 0 01.907 1.191l-6.236 4.752a.747.747 0 01-.452.153z"
      />
      <path
        fill="#4DA4FF"
        vectorEffect="non-scaling-stroke"
        d="M2.656 21.32a.743.743 0 01-1.336-.653L8.236 6.505a.745.745 0 01.428-.38.724.724 0 01.663.103l6.303 4.609 5.73-8.212a.746.746 0 011.223.854l-6.178 8.855a.726.726 0 01-1.032.176l-6.2-4.534-6.517 13.343z"
      />
    </Icon>
  )
}

export const HeatmapIcon = ({ size = "24", strokeWidth = "none" }) => {
  return (
    <Icon size={size} stroke="none" strokeWidth={strokeWidth}>
      <rect
        width="5"
        height="5"
        x="2"
        y="2.5"
        fill="#4DA4FF"
        rx="1"
        vectorEffect="non-scaling-stroke"
      />
      <rect
        width="5"
        height="5"
        x="9.5"
        y="2.5"
        fill="#0057B2"
        rx="1"
        vectorEffect="non-scaling-stroke"
      />
      <rect
        width="5"
        height="5"
        x="17"
        y="2.5"
        fill="#9CC8F6"
        rx="1"
        vectorEffect="non-scaling-stroke"
      />
      <rect
        width="5"
        height="5"
        x="2"
        y="9.5"
        fill="#9CC8F6"
        rx="1"
        vectorEffect="non-scaling-stroke"
      />
      <rect
        width="5"
        height="5"
        x="9.5"
        y="9.5"
        fill="#9CC8F6"
        rx="1"
        vectorEffect="non-scaling-stroke"
      />
      <rect
        width="5"
        height="5"
        x="17"
        y="9.5"
        fill="#4DA4FF"
        rx="1"
        vectorEffect="non-scaling-stroke"
      />
      <rect
        width="5"
        height="5"
        x="2"
        y="16.5"
        fill="#4DA4FF"
        rx="1"
        vectorEffect="non-scaling-stroke"
      />
      <rect
        width="5"
        height="5"
        x="9.5"
        y="16.5"
        fill="#4DA4FF"
        rx="1"
        vectorEffect="non-scaling-stroke"
      />
      <rect
        width="5"
        height="5"
        x="17"
        y="16.5"
        fill="#0057B2"
        rx="1"
        vectorEffect="non-scaling-stroke"
      />
    </Icon>
  )
}

export const DonutIcon = ({ size = "24", strokeWidth = "2" }) => {
  return (
    <Icon size={size} stroke="#FFF" strokeWidth={strokeWidth}>
      <path
        vectorEffect="non-scaling-stroke"
        fill="#4DA4FF"
        d="M23 12a11 11 0 10-20.952 4.686l4.976-2.343A5.5 5.5 0 1117.5 12H23z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        fill="#003C7A"
        d="M2.047 16.686A11 11 0 0022.999 12h-5.5a5.5 5.5 0 01-10.476 2.343l-4.976 2.343z"
      />
    </Icon>
  )
}

export const ScatterplotIcon = ({ size = "24", strokeWidth = "none" }) => {
  return (
    <Icon size={size} stroke="none" strokeWidth={strokeWidth}>
      <rect width="20" height="2" x="2" y="20" fill="#003C7A" rx="1" />
      <circle cx="5" cy="5" r="3" fill="#4DA4FF" />
      <circle cx="10" cy="15" r="3" fill="#4DA4FF" />
      <circle cx="19" cy="10.5" r="3" fill="#4DA4FF" />
    </Icon>
  )
}

export const DownloadIcon = ({ size = "24", strokeWidth = 2 }) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth}>
      <path
        d="M21.2 15c.7-1.2 1-2.5.7-3.9-.6-2-2.4-3.5-4.4-3.5h-1.2c-.7-3-3.2-5.2-6.2-5.6-3-.3-5.9 1.3-7.3 4-1.2 2.5-1 6.5.5 8.8M12 19.8V12M16 17l-4 4-4-4"
        vectorEffect="non-scaling-stroke"
      />
    </Icon>
  )
}

export const ChevronRightIcon = ({ size = "24", strokeWidth = 2 }) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth}>
      <path d="M9 18l6-6-6-6" vectorEffect="non-scaling-stroke" />
    </Icon>
  )
}

export const ChevronLeftIcon = ({ size = "24", strokeWidth = 2 }) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth}>
      <path d="M15 18l-6-6 6-6" vectorEffect="non-scaling-stroke" />
    </Icon>
  )
}

export const ChevronUpIcon = ({ size = "24", strokeWidth = 2 }) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth}>
      <path d="M18 15l-6-6-6 6" vectorEffect="non-scaling-stroke" />
    </Icon>
  )
}

export const ChevronDownIcon = ({ size = "24", strokeWidth = 2 }) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth}>
      <path d="M6 9l6 6 6-6" vectorEffect="non-scaling-stroke" />
    </Icon>
  )
}

export const AddIcon = ({
  size = "24",
  strokeWidth = 2,
  stroke = "currentcolor",
}) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth} stroke={stroke}>
      <line x1="12" y1="5" x2="12" y2="19" vectorEffect="non-scaling-stroke" />
      <line x1="5" y1="12" x2="19" y2="12" vectorEffect="non-scaling-stroke" />
    </Icon>
  )
}

export const QuestionIcon = ({ size = "24", strokeWidth = 2 }) => {
  return (
    <Icon size={size}>
      <path
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
        vectorEffect="non-scaling-stroke"
      />
      <path
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.09 9a3 3 0 015.83 1c0 2-3 3-3 3"
        vectorEffect="non-scaling-stroke"
      />
      <circle cx="12" cy="17" r="1.5" fill="currentcolor" stroke="none" />
    </Icon>
  )
}

export const ListIcon = ({ size = "24", strokeWidth = 2 }) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth}>
      <line x1="8" y1="6" x2="21" y2="6" vectorEffect="non-scaling-stroke" />
      <line x1="8" y1="12" x2="21" y2="12" vectorEffect="non-scaling-stroke" />
      <line x1="8" y1="18" x2="21" y2="18" vectorEffect="non-scaling-stroke" />
      <line x1="3" y1="6" x2="3.01" y2="6" vectorEffect="non-scaling-stroke" />
      <line
        x1="3"
        y1="12"
        x2="3.01"
        y2="12"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="3"
        y1="18"
        x2="3.01"
        y2="18"
        vectorEffect="non-scaling-stroke"
      />
    </Icon>
  )
}

export const ImageIcon = ({
  size = "24",
  strokeWidth = 2,
  stroke = "currentcolor",
}) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth} stroke={stroke}>
      <rect
        x="3"
        y="3"
        width="18"
        height="18"
        rx="2"
        vectorEffect="non-scaling-stroke"
      />
      <circle cx="8.5" cy="8.5" r="1.5" vectorEffect="non-scaling-stroke" />
      <path d="M20.4 14.5L16 10 4 20" vectorEffect="non-scaling-stroke" />
    </Icon>
  )
}

export const UploadIcon = ({ size = "24", strokeWidth = 2 }) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth}>
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.2 15c.7-1.2 1-2.5.7-3.9-.6-2-2.4-3.5-4.4-3.5h-1.2c-.7-3-3.2-5.2-6.2-5.6-3-.3-5.9 1.3-7.3 4-1.2 2.5-1 6.5.5 8.8m8.7-1.6V21"
      />
      <path vectorEffect="non-scaling-stroke" d="M16 16l-4-4-4 4" />
    </Icon>
  )
}

export const TableIcon = ({ size = "24", strokeWidth = 2 }) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth}>
      <path
        d="M3 3h18v18H3zM21 9H3M21 15H3M12 3v18"
        vectorEffect="non-scaling-stroke"
      />
    </Icon>
  )
}

export const MoreIcon = ({ size = "24", strokeWidth = 2 }) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth}>
      <circle cx="12" cy="12" r="1" vectorEffect="non-scaling-stroke" />
      <circle cx="12" cy="5" r="1" vectorEffect="non-scaling-stroke" />
      <circle cx="12" cy="19" r="1" vectorEffect="non-scaling-stroke" />
    </Icon>
  )
}

export const TrashIcon = ({ size = "24", strokeWidth = 2 }) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth}>
      <polyline vectorEffect="non-scaling-stroke" points="3 6 5 6 21 6" />
      <path
        vectorEffect="non-scaling-stroke"
        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
      />
      <line vectorEffect="non-scaling-stroke" x1="10" y1="11" x2="10" y2="17" />
      <line vectorEffect="non-scaling-stroke" x1="14" y1="11" x2="14" y2="17" />
    </Icon>
  )
}

export const CheckIcon = ({ size = "24", strokeWidth = 2 }) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth}>
      <path d="M20 6L9 17 4 12" vectorEffect="non-scaling-stroke" />
    </Icon>
  )
}

export const ErrorIcon = ({ size = "24", strokeWidth = 2 }) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth}>
      <path d="M7.86 2L16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2z" vectorEffect="non-scaling-stroke" />
      <path d="M12 8L12 12" vectorEffect="non-scaling-stroke" />
      <path d="M12 16L12.01 16" vectorEffect="non-scaling-stroke" />
    </Icon>
  )
}

export const LighbulbIcon = ({ size = "24", strokeWidth = 2 }) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth}>
      <path d="M1 12L3 12" vectorEffect="non-scaling-stroke" />
      <path d="M4.222 4.222L5.636 5.636" vectorEffect="non-scaling-stroke" />
      <path d="M12 1L12 3" vectorEffect="non-scaling-stroke" />
      <path d="M19.778 4.222L18.364 5.636" vectorEffect="non-scaling-stroke" />
      <path d="M23 12L21 12" vectorEffect="non-scaling-stroke" />
      <path d="M18 12a6 6 0 10-12 0 5.996 5.996 0 004 5.651V22h4v-4.349c2.329-.824 4-3.04 4-5.651z" vectorEffect="non-scaling-stroke" />
    </Icon>
  )
}

export const HighlightUpperLeftIcon = ({
  size = "24",
  strokeWidth = 2,
  stroke = "currentcolor",
  fill = "currentcolor",
}) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth} stroke={stroke}>
      <path
        fill="currentcolor"
        d="M2 2H10V10H2z"
        vectorEffect="non-scaling-stroke"
      />
      <path d="M14 2H22V10H14z" vectorEffect="non-scaling-stroke" />
      <path d="M2 14H10V22H2z" vectorEffect="non-scaling-stroke" />
      <path d="M14 14H22V22H14z" vectorEffect="non-scaling-stroke" />
    </Icon>
  )
}

export const HighlightUpperRightIcon = ({
  size = "24",
  strokeWidth = 2,
  stroke = "currentcolor",
  fill = "currentcolor",
}) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth} stroke={stroke}>
      <path d="M2 2H10V10H2z" vectorEffect="non-scaling-stroke" />
      <path
        fill="currentcolor"
        d="M14 2H22V10H14z"
        vectorEffect="non-scaling-stroke"
      />
      <path d="M2 14H10V22H2z" vectorEffect="non-scaling-stroke" />
      <path d="M14 14H22V22H14z" vectorEffect="non-scaling-stroke" />
    </Icon>
  )
}

export const HighlightBottomLeftIcon = ({
  size = "24",
  strokeWidth = 2,
  stroke = "currentcolor",
  fill = "currentcolor",
}) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth} stroke={stroke}>
      <path d="M2 2H10V10H2z" vectorEffect="non-scaling-stroke" />
      <path d="M14 2H22V10H14z" vectorEffect="non-scaling-stroke" />
      <path
        fill="currentcolor"
        d="M2 14H10V22H2z"
        vectorEffect="non-scaling-stroke"
      />
      <path d="M14 14H22V22H14z" vectorEffect="non-scaling-stroke" />
    </Icon>
  )
}

export const HighlightBottomRightIcon = ({
  size = "24",
  strokeWidth = 2,
  stroke = "currentcolor",
  fill = "currentcolor",
}) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth} stroke={stroke}>
      <path d="M2 2H10V10H2z" vectorEffect="non-scaling-stroke" />
      <path d="M14 2H22V10H14z" vectorEffect="non-scaling-stroke" />
      <path d="M2 14H10V22H2z" vectorEffect="non-scaling-stroke" />
      <path
        fill={fill}
        d="M14 14H22V22H14z"
        vectorEffect="non-scaling-stroke"
      />
    </Icon>
  )
}

export const NumericalIcon = ({
  size = "24",
  strokeWidth = "none",
  fill = "currentcolor",
}) => {
  return (
    <Icon size={size} stroke="none" strokeWidth={strokeWidth} fill={fill}>
      <path
        d="M8.272 9.5V8.411H6.94V3.218H4.87l-1.503 1.98.864.675L5.51 4.199h.081v4.212H3.898V9.5h4.374zM12.821 6.494c.945 0 1.665-.72 1.665-1.692s-.72-1.692-1.665-1.692c-.945 0-1.665.72-1.665 1.692s.72 1.692 1.665 1.692zm0-.855c-.459 0-.756-.36-.756-.837s.297-.837.756-.837c.46 0 .756.36.756.837s-.297.837-.756.837zM18.165 9.608c1.152 0 1.899-.549 2.367-1.503l-1.116-.648c-.19.513-.576.936-1.251.936-.801 0-1.332-.54-1.332-1.53V5.855c0-.99.53-1.53 1.332-1.53.675 0 1.008.351 1.17.873l1.179-.612c-.46-1.008-1.197-1.476-2.35-1.476-1.691 0-2.78 1.17-2.78 3.294s1.089 3.204 2.78 3.204zM6.324 22.238v-.864c1.197-.198 1.755-.945 1.755-1.827 0-.918-.603-1.431-1.863-1.719v-1.494c.432.099.72.297.936.54l.819-.738c-.396-.45-.927-.729-1.647-.81v-.846H5.298v.846c-1.161.135-1.782.765-1.782 1.71 0 .873.549 1.467 1.899 1.746v1.62a2.147 2.147 0 01-1.27-.675l-.81.738c.45.495 1.054.864 1.963.945v.828h1.026zM4.758 17c0-.351.198-.594.657-.684v1.332c-.46-.126-.657-.306-.657-.648zm2.079 2.646c0 .342-.198.621-.621.738v-1.422c.513.153.62.423.62.684zM14.42 21.5l4.355-6.282h-1.188L13.231 21.5h1.188zm-.73-2.826c1.044 0 1.62-.612 1.62-1.782s-.576-1.782-1.62-1.782-1.62.612-1.62 1.782.576 1.782 1.62 1.782zm0-.774c-.306 0-.468-.234-.468-.774v-.468c0-.54.162-.774.468-.774.306 0 .468.234.468.774v.468c0 .54-.162.774-.468.774zm4.626 3.708c1.044 0 1.62-.612 1.62-1.782s-.576-1.782-1.62-1.782-1.62.612-1.62 1.782.576 1.782 1.62 1.782zm0-.774c-.306 0-.468-.234-.468-.774v-.468c0-.54.162-.774.468-.774.306 0 .468.234.468.774v.468c0 .54-.162.774-.468.774z"
        vectorEffect="non-scaling-stroke"
      />
    </Icon>
  )
}

export const CategoricalIcon = ({
  size = "24",
  strokeWidth = "none",
  fill = "currentcolor",
}) => {
  return (
    <Icon size={size} stroke="none" strokeWidth={strokeWidth} fill={fill}>
      <path
        fill="#FB7646"
        d="M13.5 2.5H21.5V10.5H13.5z"
        vectorEffect="non-scaling-stroke"
      />
      <circle
        cx="6.5"
        cy="6.5"
        r="4.5"
        fill="#6C6CE5"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fill="#B52189"
        d="M6.5 13l4.5 4.5L6.5 22 2 17.5 6.5 13z"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fill="#81CDE3"
        d="M17.5 13l3.897 2.25v4.5L17.5 22l-3.897-2.25v-4.5L17.5 13z"
        vectorEffect="non-scaling-stroke"
      />
    </Icon>
  )
}

export const DatetimeIcon = ({
  size = "24",
  strokeWidth = "none",
  fill = "currentcolor",
}) => {
  return (
    <Icon size={size} stroke="none" strokeWidth={strokeWidth} fill={fill}>
      <path
        fill="#F4F4F6"
        d="M.5 22.5a1 1 0 001 1h21a1 1 0 001-1v-15H.5v15z"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fill="#FA7268"
        d="M22.5 2.5h-21a1 1 0 00-1 1v4h23v-4a1 1 0 00-1-1z"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fill="#1B1D23"
        d="M5 5V1a.5.5 0 01.5-.5h1A.5.5 0 017 1v4a.5.5 0 01-.5.5h-1A.5.5 0 015 5zM17 5V1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-1A.5.5 0 0117 5z"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fill="#979CA5"
        d="M20 17h-1.5v-3H20a.5.5 0 100-1h-1.5v-2.5a.5.5 0 10-1 0V13h-3v-2.5a.5.5 0 10-1 0V13h-3v-2.5a.5.5 0 10-1 0V13h-3v-2.5a.5.5 0 10-1 0V13H4a.5.5 0 100 1h1.5v3H4a.5.5 0 100 1h1.5v2.5a.5.5 0 101 0V18h3v2.5a.5.5 0 101 0V18h3v2.5a.5.5 0 101 0V18h3v2.5a.5.5 0 101 0V18H20a.5.5 0 100-1zM6.5 17v-3h3v3h-3zm4 0v-3h3v3h-3zm4 0v-3h3v3h-3z"
        vectorEffect="non-scaling-stroke"
      />
    </Icon>
  )
}

export const RocketIcon = ({
  size = "28",
  viewBox = "0 0 28 28",
  fill = "none",
  stroke = "none",
  strokeWidth = 0,
}) => {
  return (
    <Icon
      size={size}
      viewBox={viewBox}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    >
      <path
        d="M6.97288 13.5406C6.81793 13.5406 6.66982 13.4791 6.56044 13.3697L2.56142 9.37065C2.37343 9.18266 2.3364 8.891 2.47141 8.662C2.90891 7.92001 3.42161 7.25322 3.99525 6.67957C6.88628 3.78854 11.1804 2.96595 14.9339 4.58293C15.1111 4.65927 15.2387 4.81792 15.2757 5.00705C15.3127 5.19589 15.254 5.391 15.1185 5.52829L7.38816 13.3668C7.27936 13.4776 7.13011 13.54 6.97516 13.5406C6.97401 13.5406 6.97344 13.5406 6.97288 13.5406Z"
        fill="#C0C3C9"
      />
      <path
        d="M19.0396 25.6083C18.8886 25.6083 18.7393 25.5499 18.6271 25.4374L14.5928 21.403C14.4828 21.2934 14.4213 21.1444 14.4219 20.9889C14.4225 20.8337 14.4845 20.685 14.5951 20.5759L22.4404 12.8157C22.5772 12.6807 22.7714 12.6223 22.96 12.6573C23.1485 12.6932 23.3075 12.8197 23.3844 12.9954C25.0438 16.768 24.2326 21.0891 21.3182 24.0038C20.7457 24.5766 20.0786 25.089 19.3358 25.5274C19.2435 25.5818 19.1415 25.6083 19.0396 25.6083Z"
        fill="#C0C3C9"
      />
      <path
        d="M4.69053 15.9491C4.60338 16.1662 4.65408 16.414 4.81928 16.5792L11.4188 23.1787C11.5305 23.2904 11.6797 23.3496 11.8312 23.3496C11.9059 23.3496 11.9805 23.3354 12.0523 23.3063C12.1622 23.2612 20.5544 19.7152 24.8419 10.8437L17.1263 3.12807C8.24059 7.40406 4.73475 15.8388 4.69053 15.9491Z"
        fill="#E9EAEC"
      />
      <path
        d="M27.0968 0.905758C26.9795 0.787837 26.8086 0.724318 26.653 0.734859C22.9541 0.927866 19.8011 1.84214 17.1289 3.12804L24.8445 10.8437C26.1339 8.17592 27.0564 5.03239 27.2654 1.35038C27.2751 1.18489 27.2136 1.02339 27.0968 0.905758Z"
        fill="#FA7268"
      />
      <path
        d="M15.9579 14.957C15.2111 14.957 14.4643 14.6728 13.8958 14.1042C12.7587 12.9672 12.7587 11.1167 13.8958 9.97961C15.0334 8.84143 16.8842 8.84257 18.0207 9.97961C18.5715 10.5305 18.8752 11.2628 18.8752 12.0418C18.8752 12.8211 18.5715 13.5534 18.0207 14.1042C17.4521 14.6728 16.7053 14.957 15.9579 14.957Z"
        fill="#3CB7DC"
      />
      <path
        d="M3.21402 26.2807C2.78564 26.2807 2.44669 26.26 2.28946 26.2483C2.00122 26.2269 1.77221 25.9979 1.75113 25.7097C1.70955 25.1522 1.55631 22.3182 2.60392 21.2709C3.15478 20.72 3.88679 20.4167 4.66609 20.4167C5.44539 20.4167 6.1774 20.72 6.72827 21.2709C7.86531 22.4082 7.86531 24.2585 6.72827 25.3958C5.97688 26.1474 4.30436 26.2807 3.21402 26.2807Z"
        fill="#F2AF39"
      />
    </Icon>
  )
}


export const NumericalIconSm = ({ size = "16", strokeWidth = 1.5 }) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth}>
      <path d="M1.334 4h5.333m5.334-2.667v5.334M9.334 4h5.333M2.115 10.114l3.771 3.771m-3.771 0l3.771-3.77m3.449.635h5.333m-5.333 2.5h5.333" vectorEffect="non-scaling-stroke" />
    </Icon>
  )
}

export const CategoricalIconSm = ({ size = "16", strokeWidth = 1.5 }) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth}>
      <circle cx="4" cy="4" r="3" fill="currentcolor" />
      <path
        fill="currentcolor"
        d="M12 1l3 6H9l3-6zM4 9l3 3-3 3-3-3 3-3zM12 9l2.598 1.5v3L12 15l-2.598-1.5v-3L12 9z"
        vectorEffect="non-scaling-stroke"
      />
    </Icon>
  )
}

export const DatetimeIconSm = ({ size = "16", strokeWidth = 1.5 }) => {
  return (
    <Icon size={size} strokeWidth={strokeWidth}>
      <path d="M12.667 2.667H3.333C2.597 2.667 2 3.264 2 4v9.333c0 .737.597 1.334 1.333 1.334h9.334c.736 0 1.333-.597 1.333-1.334V4c0-.736-.597-1.333-1.333-1.333zM10.666 1.333V4M5.334 1.333V4M2 6.667h12" vectorEffect="non-scaling-stroke" />
    </Icon>
  )
}