import { useSession } from "next-auth/client"
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
} from "@chakra-ui/react"

const APP_DOMAIN = process.env.NEXT_PUBLIC_APP_DOMAIN
import { ButtonLink } from "@/components/Link"

const UserDetails = () => {
  const [session, loading] = useSession()

  return (
    <>
      {session ? (
        <Box>
          <Menu>
            <MenuButton
              as={Button}
              size="sm"
              variant="nav"
              px="0"
              overflow="hidden"
              color="transparent"
              overflow="hidden"
              w="2.25rem"
              h="2.25rem"
              border="0.125rem solid #FFF"
            >
              {session.user?.image ? (
                <Image
                  src={session.user.image}
                  boxSize="2rem"
                  alt={session.user.name}
                  borderRadius="full"
                />
              ) : (
                "O"
              )}
            </MenuButton>
            <MenuList mt="0.125rem" borderRadius="md" boxShadow="md">
              <MenuItem
                as={ButtonLink}
                variant="menuLink"
                href={`/api/auth/signout?callbackUrl=${APP_DOMAIN}`}
                textTransform="none"
              >
                {"Sign out"}
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      ) : (
        <ButtonLink
          href={`/api/auth/signin?callbackUrl=${APP_DOMAIN}/datasets`}
          variant="nav"
          size="sm"
        >
          {"Sign in"}
        </ButtonLink>
      )}
    </>
  )
}

export default UserDetails
